import React from 'react'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import { projectsAll, projectsResidential, projectsCommercial, projectsFarming } from '../projectsBrief';
import { Container, Col, Row } from 'react-bootstrap/';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Projects = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Projects</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </section>
            <section id="projects" className="entry">
                <Container>
                    <Box>
                        <Tabs value={value} onChange={handleChange} style={{ borderBottom: 'unset' }} variant="scrollable" centered>
                            <Tab label="All Projects" {...a11yProps(0)} />
                            <Tab label="All Residential Projects" {...a11yProps(1)} />
                            <Tab label="All Commercial Projects" {...a11yProps(2)} />
                            <Tab label="All Farming Projects" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Row>
                            {projectsAll.map((item, index) => {
                                return (
                                    <Col md={4} key={index}>
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <Link to={item.url}>
                                                <div className="project-single">
                                                    <img src={item.image} alt={item.imageAlt} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </Link>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Row>
                            {projectsResidential.map((item, index) => {
                                return (
                                    <Col md={4} key={index}>
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <Link to={item.url}>
                                                <div className="project-single">
                                                    <img src={item.image} alt={item.imageAlt} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </Link>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Row>
                            {projectsCommercial.map((item, index) => {
                                return (
                                    <Col md={4} key={index}>
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <Link to={item.url}>
                                                <div className="project-single">
                                                    <img src={item.image} alt={item.imageAlt} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </Link>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Row>
                            {projectsFarming.map((item, index) => {
                                return (
                                    <Col md={4} key={index}>
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <Link to={item.url}>
                                                <div className="project-single">
                                                    <img src={item.image} alt={item.imageAlt} className="img-fluid" />
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </Link>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })}
                        </Row>
                    </TabPanel>
                </Container>
            </section>
        </div>
    )
}

export default Projects
