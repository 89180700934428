import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Container from 'react-bootstrap/Container';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ThankYou = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Thank You</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Thank You</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2 className="text-center">Thank You For Your Message..!!</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <p className="text-center">Message Sent Successfully! We have recieved your mail and we will contact you shortly.</p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <p className="text-center">Click to go on <a href="/">Homepage</a></p>
                    </ScrollAnimation>
                </Container>
            </section>
        </div>
    )
}

export default ThankYou
