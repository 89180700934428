// import Project1 from './assets/images/projects/project-1.jpg'
// import Project2 from './assets/images/projects/project-2.jpg'
// import Project3 from './assets/images/projects/project-3.jpg'
// import Project4 from './assets/images/projects/project-4.jpg'
// import Project5 from './assets/images/projects/project-5.jpg'
// import Project6 from './assets/images/projects/project-6.jpg'
// import Project7 from './assets/images/projects/project-7.jpg'
// import Project8 from './assets/images/projects/project-8.jpg'
// import Project9 from './assets/images/projects/project-9.jpg'
// import Project10 from './assets/images/projects/project-10.jpg'
// import Project11 from './assets/images/projects/project-11.jpg'
// import Project12 from './assets/images/projects/project-12.jpg'
// import Project13 from './assets/images/projects/project-13.jpg'
// import Project14 from './assets/images/projects/project-14.jpg'
import corner_of_dorset_and_edina_road_ferntree_gully1 from './assets/images/projects/corner_of_dorset_and_edina_road_ferntree_gully1.png'
import stud_road_rowville1 from './assets/images/projects/stud_road_rowville1.png'
import the_haven_bayswater1 from './assets/images/projects/the_haven_bayswater1.png'
import hayden_court_knoxfield1 from './assets/images/projects/hayden_court_knoxfield1.png'
import murray_crescent_rowville1 from './assets/images/projects/murray_crescent_rowville1.png'
import scoresby_road_368_ferntree_gully1 from './assets/images/projects/scoresby_road_368_ferntree_gully1.png'
import peck_street_bayswater1 from './assets/images/projects/peck_street_bayswater1.png'
import kevin_avenue_ferntree_gully1 from './assets/images/projects/kevin_avenue_ferntree_gully1.png'
import david_street_knoxfield1 from './assets/images/projects/david_street_knoxfield1.png'
import gresford_road_wantirna1 from './assets/images/projects/gresford_road_wantirna1.png'
import yarran_grove_bayswater1 from './assets/images/projects/yarran_grove_bayswater1.png'
import charlotte_road_boronia1 from './assets/images/projects/charlotte_road_boronia1.png'
import springsong_pass_chirnside1 from './assets/images/projects/springsong_pass_chirnside1.png'
import mitre_crescent_bayswater1 from './assets/images/projects/mitre_crescent_bayswater1.png'
import burton_court_13_15_bayswater1 from './assets/images/projects/burton_court_13_15_bayswater1.png'
import burton_court_15_bayswater1 from './assets/images/projects/burton_court_15_bayswater1.png'
import burton_court_17_bayswater1 from './assets/images/projects/burton_court_17_bayswater1.png'
import cumberland_avenue_bayswater1 from './assets/images/projects/cumberland_avenue_bayswater1.png'
import lysterfield_road_lysterfield1 from './assets/images/projects/lysterfield_road_lysterfield1.png'
import north_road_albury1 from './assets/images/projects/north_road_albury1.png'
import north_road_rear_side_albury1 from './assets/images/projects/north_road_rear_side_albury1.png'



// const rowville = {
//     name: "Rowville",
//     image: Project1,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const wantirna = {
//     name: "Wantirna",
//     image: Project2,
//     imageAlt: "Project",
//     url: "/our-projects/wantirna"
// }

// const rowville2 = {
//     name: "Rowville",
//     image: Project3,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const boronia = {
//     name: "Boronia",
//     image: Project4,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const boronia2 = {
//     name: "Boronia",
//     image: Project5,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const bayswater = {
//     name: "Bayswater",
//     image: Project6,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const bayswater2 = {
//     name: "Bayswater",
//     image: Project7,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const ferntreeGully = {
//     name: "Ferntree Gully",
//     image: Project8,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const ferntreeGully2 = {
//     name: "Ferntree Gully",
//     image: Project9,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const bayswater3 = {
//     name: "Corner of Dorset and Edina Road, Ferntree Gully, 3156",
//     image: Project10,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const bayswater4 = {
//     name: "Bayswater",
//     image: Project11,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const knoxfield = {
//     name: "Knoxfield",
//     image: Project12,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const rowville3 = {
//     name: "Rowville",
//     image: Project13,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

// const knoxfield2 = {
//     name: "Knoxfield",
//     image: Project14,
//     imageAlt: "Project",
//     url: "/our-projects/rowville"
// }

const corner_of_dorset_and_edina_road_ferntree_gully = {
    name: "Corner of Dorset and Edina Road, Ferntree Gully, 3156",
    image: corner_of_dorset_and_edina_road_ferntree_gully1,
    imageAlt: "Project",
    url: "/our-projects/corner_of_dorset_and_edina_road_ferntree_gully"
}

const stud_road_rowville = {
    name: "Stud Road, Rowville, 3178",
    image: stud_road_rowville1,
    imageAlt: "Project",
    url: "/our-projects/stud_road_rowville"
}

const the_haven_bayswater = {
    name: "The Haven, Bayswater, 3153",
    image: the_haven_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/the_haven_bayswater"
}

const hayden_court_knoxfield = {
    name: "Hayden Court, Knoxfield, 3180",
    image: hayden_court_knoxfield1,
    imageAlt: "Project",
    url: "/our-projects/hayden_court_knoxfield"
}

const murray_crescent_rowville = {
    name: "Murray Crescent, Rowville, 3178",
    image: murray_crescent_rowville1,
    imageAlt: "Project",
    url: "/our-projects/murray_crescent_rowville"
}

const scoresby_road_368_ferntree_gully = {
    name: "368 Scoresby Road, Ferntree Gully, 3156",
    image: scoresby_road_368_ferntree_gully1,
    imageAlt: "Project",
    url: "/our-projects/scoresby_road_368_ferntree_gully"
}

const peck_street_bayswater = {
    name: "Peck Street, Bayswater, 3153",
    image: peck_street_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/peck_street_bayswater"
}

const kevin_avenue_ferntree_gully = {
    name: "Kevin Avenue, Ferntree Gully, 3156",
    image: kevin_avenue_ferntree_gully1,
    imageAlt: "Project",
    url: "/our-projects/kevin_avenue_ferntree_gully"
}

const david_street_knoxfield = {
    name: "David Street, Knoxfield, 3180",
    image: david_street_knoxfield1,
    imageAlt: "Project",
    url: "/our-projects/david_street_knoxfield"
}

const gresford_road_wantirna  = {
    name: "Gresford Road, Wantirna, 3152",
    image: gresford_road_wantirna1,
    imageAlt: "Project",
    url: "/our-projects/gresford_road_wantirna"
}

const yarran_grove_bayswater = {
    name: "Yarran Grove, Bayswater, 3153",
    image: yarran_grove_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/yarran_grove_bayswater"
}

const charlotte_road_boronia = {
    name: "Charlotte Road, Boronia, 3155",
    image: charlotte_road_boronia1,
    imageAlt: "Project",
    url: "/our-projects/charlotte_road_boronia"
}

const springsong_pass_chirnside = {
    name: "Springsong Pass, Chirnside, 3116",
    image: springsong_pass_chirnside1,
    imageAlt: "Project",
    url: "/our-projects/springsong_pass_chirnside"
}

const mitre_crescent_bayswater = {
    name: "Mitre Crescent, Bayswater, 3153",
    image: mitre_crescent_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/mitre_crescent_bayswater"
}

const burton_court_13_15_bayswater = {
    name: "13-15 Burton Court, Bayswater, 3153",
    image: burton_court_13_15_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/burton_13_15_court_bayswater"
}

const burton_court_15_bayswater = {
    name: "15 Burton Court, Bayswater, 3153",
    image: burton_court_15_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/burton_15_court_bayswater"
}

const burton_court_17_bayswater = {
    name: "17 Burton Court, Bayswater, 3153",
    image: burton_court_17_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/burton_court_bayswater"
}

const cumberland_avenue_bayswater = {
    name: "Cumberland Avenue, Bayswater, 3153",
    image: cumberland_avenue_bayswater1,
    imageAlt: "Project",
    url: "/our-projects/cumberland_avenue_bayswater"
}

const lysterfield_road_lysterfield = {
    name: "Lysterfield Road, Lysterfield, 3156",
    image: lysterfield_road_lysterfield1,
    imageAlt: "Project",
    url: "/our-projects/lysterfield_road_lysterfield"
}

const north_road_albury = {
    name: "North Road, Albury, 2640",
    image: north_road_albury1,
    imageAlt: "Project",
    url: "/our-projects/north_road_albury"
}

const north_road_rear_side_albury = {
    name: "North Road (Rear Side), Albury, 2640",
    image: north_road_rear_side_albury1,
    imageAlt: "Project",
    url: "/our-projects/north_road_rear_side_albury"
}

export const projectsAll = [corner_of_dorset_and_edina_road_ferntree_gully,stud_road_rowville,the_haven_bayswater,hayden_court_knoxfield,murray_crescent_rowville,scoresby_road_368_ferntree_gully,peck_street_bayswater,kevin_avenue_ferntree_gully,david_street_knoxfield,gresford_road_wantirna,yarran_grove_bayswater,charlotte_road_boronia,springsong_pass_chirnside,mitre_crescent_bayswater, burton_court_13_15_bayswater, burton_court_15_bayswater, burton_court_17_bayswater, cumberland_avenue_bayswater, lysterfield_road_lysterfield, north_road_albury, north_road_rear_side_albury]
export const projectsResidential = [the_haven_bayswater,hayden_court_knoxfield,murray_crescent_rowville,scoresby_road_368_ferntree_gully,peck_street_bayswater,kevin_avenue_ferntree_gully,david_street_knoxfield,gresford_road_wantirna,yarran_grove_bayswater,charlotte_road_boronia,springsong_pass_chirnside,mitre_crescent_bayswater]
export const projectsCommercial = [corner_of_dorset_and_edina_road_ferntree_gully,stud_road_rowville, burton_court_13_15_bayswater, burton_court_15_bayswater, burton_court_17_bayswater,north_road_albury,north_road_rear_side_albury, cumberland_avenue_bayswater,]
export const projectsFarming = [lysterfield_road_lysterfield]

export const projectsDevelopmentAll = [corner_of_dorset_and_edina_road_ferntree_gully,stud_road_rowville,hayden_court_knoxfield,murray_crescent_rowville, burton_court_13_15_bayswater, burton_court_15_bayswater, burton_court_17_bayswater, lysterfield_road_lysterfield, north_road_albury, north_road_rear_side_albury]
export const projectsDevelopmentResidential = [the_haven_bayswater,hayden_court_knoxfield,murray_crescent_rowville]
export const projectsDevelopmentCommercial = [corner_of_dorset_and_edina_road_ferntree_gully,stud_road_rowville, burton_court_13_15_bayswater, burton_court_15_bayswater, burton_court_17_bayswater,north_road_albury,north_road_rear_side_albury]
export const projectsDevelopmentFarming = [lysterfield_road_lysterfield]

export const projectsConstructionAll = [scoresby_road_368_ferntree_gully,peck_street_bayswater,kevin_avenue_ferntree_gully,david_street_knoxfield,gresford_road_wantirna,yarran_grove_bayswater,charlotte_road_boronia,springsong_pass_chirnside,mitre_crescent_bayswater, cumberland_avenue_bayswater]
export const projectsConstructionResidential = [scoresby_road_368_ferntree_gully,peck_street_bayswater,kevin_avenue_ferntree_gully,david_street_knoxfield,gresford_road_wantirna,yarran_grove_bayswater,charlotte_road_boronia,springsong_pass_chirnside,mitre_crescent_bayswater]
export const projectsConstructionCommercial = [cumberland_avenue_bayswater,]
