import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Container from 'react-bootstrap/Container';

const Sitemap = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Sitemap</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Sitemap</li>
                    </ul>
                </div>
            </section>
            <section className="entry sitemap">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about-us">Company</a></li>
                            <li><a href="/services">Services</a></li>
                            <li><a href="/our-projects">Projects</a></li>
                            <li><a href="/contact-us">Contact Us</a></li>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </ScrollAnimation>
                </Container>
            </section>
        </div>
    )
}

export default Sitemap
