import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import PlanningImg from '../assets/images/planning.png'
import Logo from '../assets/images/logo/logoSubheading.png';

const ProjectManagement = () => {
    return (
        <div>
            <ScrollAnimation animateIn="fadeInUp">
                <img src={PlanningImg} alt="House Renovation" className="img-fluid" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <h4 className="heading-4">Project Management</h4>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <p><img src={Logo} alt='logo' className='img-fluid logo' /> manages the project in a very smooth & simple manner, its planning & implementation of the day-to-day onsite activities, on priority basis, keeping in mind all the aspects of the concerned client.</p>
                <p><img src={Logo} alt='logo' className='img-fluid logo' /> ensures full & complete safety & security of all its onsite work force as per prevailing law of the land including incomplete structure / work-in-progress and raw materials whatsoever.</p>
            </ScrollAnimation>
        </div>
    )
}

export default ProjectManagement
