// import Project1 from './assets/images/projects/project-1.jpg'
// import Project2 from './assets/images/projects/project-2.jpg'
// import Project3 from './assets/images/projects/project-3.jpg'
// import Project4 from './assets/images/projects/project-4.jpg'
import corner_of_dorset_and_edina_road_ferntree_gully1 from './assets/images/projects/corner_of_dorset_and_edina_road_ferntree_gully1.png'
import corner_of_dorset_and_edina_road_ferntree_gully2 from './assets/images/projects/corner_of_dorset_and_edina_road_ferntree_gully2.png'
import stud_road_rowville1 from './assets/images/projects/stud_road_rowville1.png'
import stud_road_rowville2 from './assets/images/projects/stud_road_rowville2.png'
import the_haven_bayswater1 from './assets/images/projects/the_haven_bayswater1.png'
import the_haven_bayswater2 from './assets/images/projects/the_haven_bayswater2.png'
import hayden_court_knoxfield1 from './assets/images/projects/hayden_court_knoxfield1.png'
import hayden_court_knoxfield2 from './assets/images/projects/hayden_court_knoxfield2.png'
import murray_crescent_rowville1 from './assets/images/projects/murray_crescent_rowville1.png'
import murray_crescent_rowville2 from './assets/images/projects/murray_crescent_rowville2.png'
import scoresby_road_368_ferntree_gully1 from './assets/images/projects/scoresby_road_368_ferntree_gully1.png'
import scoresby_road_368_ferntree_gully2 from './assets/images/projects/scoresby_road_368_ferntree_gully2.png'
import scoresby_road_368_ferntree_gully3 from './assets/images/projects/scoresby_road_368_ferntree_gully3.png'
import peck_street_bayswater1 from './assets/images/projects/peck_street_bayswater1.png'
import peck_street_bayswater2 from './assets/images/projects/peck_street_bayswater2.png'
import kevin_avenue_ferntree_gully1 from './assets/images/projects/kevin_avenue_ferntree_gully1.png'
import kevin_avenue_ferntree_gully2 from './assets/images/projects/kevin_avenue_ferntree_gully2.png'
import david_street_knoxfield1 from './assets/images/projects/david_street_knoxfield1.png'
import david_street_knoxfield2 from './assets/images/projects/david_street_knoxfield2.png'
import david_street_knoxfield3 from './assets/images/projects/david_street_knoxfield3.png'
import gresford_road_wantirna1 from './assets/images/projects/gresford_road_wantirna1.png'
import gresford_road_wantirna2 from './assets/images/projects/gresford_road_wantirna2.png'
import yarran_grove_bayswater1 from './assets/images/projects/yarran_grove_bayswater1.png'
import yarran_grove_bayswater2 from './assets/images/projects/yarran_grove_bayswater2.png'
import charlotte_road_boronia1 from './assets/images/projects/charlotte_road_boronia1.png'
import charlotte_road_boronia2 from './assets/images/projects/charlotte_road_boronia2.png'
import springsong_pass_chirnside1 from './assets/images/projects/springsong_pass_chirnside1.png'
import springsong_pass_chirnside2 from './assets/images/projects/springsong_pass_chirnside2.png'
import mitre_crescent_bayswater1 from './assets/images/projects/mitre_crescent_bayswater1.png'
import mitre_crescent_bayswater2 from './assets/images/projects/mitre_crescent_bayswater2.png'
import burton_court_13_15_bayswater1 from './assets/images/projects/burton_court_13_15_bayswater1.png'
import burton_court_13_15_bayswater2 from './assets/images/projects/burton_court_13_15_bayswater2.png'
import burton_court_15_bayswater1 from './assets/images/projects/burton_court_15_bayswater1.png'
import burton_court_15_bayswater2 from './assets/images/projects/burton_court_15_bayswater2.png'
import burton_court_17_bayswater1 from './assets/images/projects/burton_court_17_bayswater1.png'
import burton_court_17_bayswater2 from './assets/images/projects/burton_court_17_bayswater2.png'
import cumberland_avenue_bayswater1 from './assets/images/projects/cumberland_avenue_bayswater1.png'
import cumberland_avenue_bayswater2 from './assets/images/projects/cumberland_avenue_bayswater2.png'
import lysterfield_road_lysterfield1 from './assets/images/projects/lysterfield_road_lysterfield1.png'
import lysterfield_road_lysterfield2 from './assets/images/projects/lysterfield_road_lysterfield2.png'
import north_road_albury1 from './assets/images/projects/north_road_albury1.png'
import north_road_albury2 from './assets/images/projects/north_road_albury2.png'
import north_road_rear_side_albury1 from './assets/images/projects/north_road_rear_side_albury1.png'
import north_road_rear_side_albury2 from './assets/images/projects/north_road_rear_side_albury2.png'

// export const rowville = {
//     heading: "Stud Road, Rowville 3178",
//     gallery: [Project1, Project2, Project3, Project4],
//     title: "Planning & Architecture",
//     name:'rowville',
//     description: [
//         {
//             paragraph: "The right size, right location, right facilities, right neighborhoods there is a lot you could dream about when it comes to One of the biggest real estate acquired by V Developer. It is a block of land measuring 4451 sqm. Step away from the Stud Park Shopping Centre and the proposed Rowville Rail, an opportunity site nearby. The location is so perfect, close to one of the main arterial roads that services major road networks such as Monash Freeway and eastlink and a major metropolitan manufacturing and commercial suburb, Dandenong. Proposed plan is to develop a mixture of medium and higher density residential development together with retail and commercial activities designed to meet the needs of the local community. The development will rise up to 5 storeys high."
//         }
//     ],
//     projectDetails: [
//         {
//             title: "Current Status",
//             data: "$180,000 P.A"
//         },
//         {
//             title: "Land Size",
//             data: "4451SQM"
//         },
//         {
//             title: "Completion Date",
//             data: "2023 - 2024"
//         },
//         {
//             title: "End Value",
//             data: "$45 Million"
//         }
//     ],

// }

// export const wantirna = {
//     heading: "Wantirna",
//     gallery: [Project4, Project3, Project2, Project1],
//     title: "Planning & Architecture",
//     name: 'wantirna',
//     description: [
//         {
//             paragraph: "The right size, right location, right facilities, right neighborhoods there is a lot you could dream about when it comes to One of the biggest real estate acquired by V Developer. It is a block of land measuring 4451 sqm. Step away from the Stud Park Shopping Centre and the proposed Rowville Rail, an opportunity site nearby. The location is so perfect, close to one of the main arterial roads that services major road networks such as Monash Freeway and eastlink and a major metropolitan manufacturing and commercial suburb, Dandenong. Proposed plan is to develop a mixture of medium and higher density residential development together with retail and commercial activities designed to meet the needs of the local community. The development will rise up to 5 storeys high."
//         },
//     ],
//     projectDetails: [
//         {
//             title: "Current Status",
//             data: "$180,000 P.A"
//         },
//         {
//             title: "Land Size",
//             data: "4451SQM"
//         },
//         {
//             title: "Completion Date",
//             data: "2023 - 2024"
//         },
//         {
//             title: "End Value",
//             data: "$45 Million"
//         }
//     ],

// }

export const corner_of_dorset_and_edina_road_ferntree_gully = {
    heading: "Corner of Dorset and Edina Road, Ferntree Gully, 3156",
    gallery: [corner_of_dorset_and_edina_road_ferntree_gully1, corner_of_dorset_and_edina_road_ferntree_gully2 ],
    title: "Planning & Architecture",
    name: 'corner_of_dorset_and_edina_road_ferntree_gully',
    description: [
        {
            paragraph: "Sitting on a 2000sqm, there are currently 3 Leased Warehouses that occupy the land. Having a dual street frontage, the opportunity on this piece of land is unthinkable. However, the proposed development on this site is a multi-story medical centre open 24*7, 365 days. This will provide the community with exceptional and comprehensive medical services. Included on-site will be pharmacy, physiotherapy, dental, X-ray, pathology, and allied health. Talks are being held with the local council to establish a one-of-a-kind Medical Center in Eastern Suburb."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "rented at $120,000 p.a."
        },
        {
            title: "Land Size",
            data: "2000SQM"
        },
        {
            title: "Completion Date",
            data: "2023 - 2024"
        },
        {
            title: "End Value",
            data: "$18 million"
        },
        {
            title: "Land Acquired",
            data: "Aug 2016"
        },
        {
            title: "Expected Rental after completion",
            data: "$1.6 million P.A."
        },
        {
            title: "Client",
            data: "V Developers Pty Ltd"
        }
    ],

}


export const stud_road_rowville = {
    heading: "Stud Road, Rowville, 3178",
    gallery: [stud_road_rowville1, stud_road_rowville2],
    title: "Planning & Architecture",
    name: 'stud_road_rowville',
    description: [
        {
            paragraph: "The right size, right location, right facilities, right neighborhoods there is a lot you could dream about when it comes to One of the biggest real estates acquired by V Developer. It is a block of land measuring 4451 sqm. Step away from the Stud Park Shopping Centre and the proposed Rowville Rail, an opportunity site nearby. The location is so perfect, close to one of the main arterial roads that services major road networks such as Monash Freeway and Eastlink and a major metropolitan manufacturing and commercial suburb, Dandenong. Proposed plan is to develop a mixture of medium and higher density residential development together with retail and commercial activities designed to meet the needs of the local community. The development will rise to 6 stories high. Comprises of Medical Centre, Childcare, Office Space, Veterinary, Studio Apartments. A 7 star rated energy efficient building which is self dependent on its energy use."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "rented at $196,000 p.a."
        },
        {
            title: "Land Size",
            data: " 4451SQM"
        },
        {
            title: "Completion Date",
            data: "2025 - 2026 "
        },
        {
            title: "Development status",
            data: "Concept Designing and Project evaluation"
        },
        {
            title: "End Value",
            data: "$61 million"
        },
      
       
    ],

}

export const the_haven_bayswater = {
    heading: "The Haven, Bayswater, 3153",
    gallery: [the_haven_bayswater1, the_haven_bayswater2],
    title: "Planning & Architecture",
    name: 'the_haven_bayswater',
    description: [
        {
            paragraph: "Comprises of 14 mixed single-story 2- and 3-bedroom units and double-story 3-bedroom units. These stylish residences meet the requirements of today’s modern families. Located in a quiet court location, surrounded by a public park. Few steps away is Bayswater Primary School."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: "4565 SQM"
        },
        {
            title: "Project Completion Date",
            data: "Jul 2019"
        },
        {
            title: "Projected end value",
            data: "$10.03 million"
        },
        {
            title: "Client",
            data: "V Developers Pty Ltd."
        },
      
       
    ],

}

export const hayden_court_knoxfield = {
    heading: "Hayden Court, Knoxfield, 3180",
    gallery: [hayden_court_knoxfield1, hayden_court_knoxfield2],
    title: "Planning & Architecture",
    name: 'hayden_court_knoxfield',
    description: [
        {
            paragraph: "Proposed 5 mixed single-story and double-story townhouses. It has extensive lifestyle benefits and modern comforts, all placed within the bowl of a quiet court. Easy access to the Major Highway, Eastlink, and Monash Freeway."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: "1404SQM"
        },
        {
            title: "Completion Date",
            data: "2021 "
        },
        {
            title: "End Value",
            data: "$5.8 million"
        },
        {
            title: "Client",
            data: " V Developers Pty Ltd."
        },
      
       
    ],

}

export const murray_crescent_rowville = {
    heading: "Murray Crescent, Rowville, 3178",
    gallery: [murray_crescent_rowville1, murray_crescent_rowville2],
    title: "Planning & Architecture",
    name: 'murray_crescent_rowville',
    description: [
        {
            paragraph: "Proposed 4 luxurious townhouses, ranging from 3 and 4 bedroom. Located in one of the premier Rowville locations and quietest street with a leafy landscape of the street. Walking distance to the local Childcare Centre, Park Ridge Primary School, Rowville Secondary College, St Simons Primary School, Stud Park Shopping Centre and Rowville Lakes Shopping center. Along with a short drive to the Monash & Eastlink Freeways."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: " Rented at $33,200 P.A"
        },
        {
            title: "Land Size",
            data: "2000SQM"
        },
        {
            title: "Land Acquired",
            data: "Dec 2020 "
        },
        {
            title: "Development Status",
            data: " Designing and Town Planning Stage."
        },
        {
            title: "Completion Date",
            data: "2023"
        },
        {
            title: "End Value",
            data: " $6.6 million"
        },
        {
            title: "Expected Rental after completion",
            data: "$202,800 P.A."
        },
      
        {
            title: "Client",
            data: "Harrison Developments Pty Ltd."
        },
      
       
    ],

}


export const scoresby_road_368_ferntree_gully = {
    heading: "368 Scoresby Road, Ferntree Gully, 3156",
    gallery: [scoresby_road_368_ferntree_gully1, scoresby_road_368_ferntree_gully2, scoresby_road_368_ferntree_gully3],
    title: "Planning & Architecture",
    name:'scoresby_road_368_ferntree_gully',
    description: [
        {
            paragraph: "Proposed Multi Story mixed 2 and 3 bedroom Contemporary 15 apartments. Located in one of the major development pockets. High density zoning of Boronia. Nearby schools include St Joseph's School, Boronia West Primary School, Boronia Heights Primary School, and Boronia K-12 College. Facilities located in the vicinity are Boronia Junction Shopping Square, Boronia Railway Station. Boronia K-12 College, Knox Basketball Stadium, Boronia Library and Dandenong Range National Park."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: " 1000SQM"
        },
        {
            title: "Completion Date",
            data: " Dec 2019 "
        },
        {
            title: "End Value",
            data: "$2.7 million"
        },
        {
            title: "Expected Rental after completion",
            data: "$80,000 P.A."
        },
        {
            title: "Client",
            data: "Prakash Hirani"
        },
      
       
    ],

}


export const peck_street_bayswater = {
    heading: "Peck Street, Bayswater, 3153",
    gallery: [peck_street_bayswater1, peck_street_bayswater2],
    title: "Planning & Architecture",
    name: 'peck_street_bayswater',
    description: [
        {
            paragraph: "Unlock the wealth with a dual occupancy development of artistically designed double story 4-bedroom townhouses. underpinned by a dream location that offers easy access to an array of quality amenities, including walking distance to Bayswater West Primary, Bayswater Primary, Bayswater Secondary, Guy Turner Reserve, Bayswater Train Station, and Bayswater Shopping Centre. While travelling a little further afield you’ll find Knox Private Hospital, Westfield Knox, The Knox School plus EastLink Freeway for convenient CBD access."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: " 730SQM"
        },
        {
            title: "Completion Date",
            data: " Dec 2021 "
        },
        {
            title: "End Value",
            data: "$1.9 million"
        },
        {
            title: "Expected Rental after completion",
            data: "$67,600 P.A."
        },
        {
            title: "Client",
            data: " Atul Varsani "
        },
      
       
    ],

}

export const kevin_avenue_ferntree_gully = {
    heading: "Kevin Avenue, Ferntree Gully, 3156",
    gallery: [kevin_avenue_ferntree_gully1, kevin_avenue_ferntree_gully2],
    title: "Planning & Architecture",
    name: 'kevin_avenue_ferntree_gully',
    description: [
        {
            paragraph: "Sitting on an enormous 945m2 allotment, proposed is a single and a double story, 4-bedroom house. The location of the house is brilliantly located within walking distance to Mountain Gate Shopping Centre, Mountain Gate Primary School, and your choice of multiple public transport."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: " 940 SQM"
        },
        {
            title: "Completion Date",
            data: "  April 2022  "
        },
        {
            title: "End Value",
            data: "$1.85 million"
        },
        {
            title: "Client",
            data: "Shanti Pindoria"
        },
      
       
    ],

}

export const david_street_knoxfield = {
    heading: "David Street, Knoxfield, 3180",
    gallery: [david_street_knoxfield1, david_street_knoxfield2, david_street_knoxfield3],
    title: "Planning & Architecture",
    name: 'david_street_knoxfield',
    description: [
        {
            paragraph: "Boasting the very best of quality and finish throughout, we surpassed our client expectations by delivering this project under time and budget. Architecturally designed brand new townhouse offering 3 double story 3 Bedrooms and Single story 4-bedroom townhouses. Conveniently located on quiet David Street at Knoxfield, walking distance to Knoxfield Shopping Village, Schools, Kinder Garden, Parks and only minutes away from Mountain Gate and Knox City shopping centers. The instant family appeal of these sensational executive residence's generous proportions and contemporary style is immeasurably enhanced by a superb location. Including 6-star energy rating, quality fixtures and fittings, excellent ensuite and family bathroom, walk-in wardrobe, gas ducted heating, LED downlights."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: " 1270 SQM"
        },
        {
            title: "Land Acquired",
            data: "Feb 2012"
        },
        {
            title: "Completion Date",
            data: "2015"
        },
        {
            title: "Total Project Value",
            data: "$ 2.1 million."
        },
        {
            title: "Client",
            data: "V Developers Pty Ltd"
        },
      
       
    ],

} 


export const gresford_road_wantirna = {
    heading: "Gresford Road, Wantirna, 3152",
    gallery: [gresford_road_wantirna1, gresford_road_wantirna2],
    title: "Planning & Architecture",
    name: 'gresford_road_wantirna',
    description: [
        {
            paragraph: "Designed and built with quality at its forefront, V Developer successfully delivered the construction of 2 new Double story townhouses at the rear of an existing single story in the most sought-after postcode in Eastern Suburb-Wantirna. Styled to meet the demands of a busy modern lifestyle, these townhouses boast vast living/dining areas with floating floors and gorgeous kitchens with stone benches, glass splash backs and stainless-steel appliances. They consist of 3 robed bedrooms, including the master with an ensuite tiled from floor to ceiling to match the family bathroom and additional downstairs powder room. Overlooking the Schultz Reserve ensures a walking distance to the reserve."
        }
    ],
    projectDetails: [

        {
            title: "Land Size",
            data: "1004 SQM"
        },
        {
            title: "Land Acquired",
            data: "Aug 2014"
        },
        {
            title: "Completion Date",
            data: "2017"
        },
        {
            title: "Total Project Value",
            data: "$ 2.4 million."
        },
        {
            title: "Client",
            data: "V Developers Pty Ltd/Ghanshyam Rabadia"
        },
      
       
    ],

}

export const yarran_grove_bayswater = {
    heading: "Yarran Grove, Bayswater, 3153",
    gallery: [yarran_grove_bayswater1, yarran_grove_bayswater2],
    title: "Planning & Architecture",
    name: 'yarran_grove_bayswater',
    description: [
        {
            paragraph: "Building a new single-story 3-bedroom dwelling behind an existing dwelling. At the same time renovating the existing dwelling to bring a new life and create a modern new look for the house."
        }
    ],
    projectDetails: [

        {
            title: "Land Size",
            data: "780 SQM"
        },
        {
            title: "Land Acquired",
            data: "Aug 2015"
        },
        {
            title: "Completion Date",
            data: "2017"
        },
        {
            title: "Total Project Value",
            data: "$1.6 million."
        },
        {
            title: "Client",
            data: "Prompt Developments Pty Ltd"
        },
      
       
    ],

} 

export const charlotte_road_boronia = {
    heading: "Charlotte Road, Boronia, 3155",
    gallery: [charlotte_road_boronia1, charlotte_road_boronia2],
    title: "Planning & Architecture",
    name: 'charlotte_road_boronia',
    description: [
        {
            paragraph: "An exquisitely luxurious double story dwelling with every corner of the home customized as per client's request. Combining luxury finishes with spacious floorplans, this residence offers a superior low maintenance lifestyle choice. With modern living features such as: soft closing custom joinery, Spanish imported marble benchtops, floor to ceiling tiling in the bathrooms, matt black tapware, engineered premium oak flooring, composite lumber deck, and exposed aggregate concrete driveway."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: "717 SQM"
        },
        {
            title: "Completion Date",
            data: "June 2021"
        },
        {
            title: "Total Project Value",
            data: " $1,900,000 "
        },
        {
            title: "Client",
            data: "Deepak Raghvani"
        },
      
       
    ],

}

export const springsong_pass_chirnside = {
    heading: "Springsong Pass, Chirnside, 3116",
    gallery: [springsong_pass_chirnside1, springsong_pass_chirnside2],
    title: "Planning & Architecture",
    name: 'springsong_pass_chirnside',
    description: [
        {
            paragraph: "Situated in the beautiful arms of Chirnside, this exquisite modern home celebrates you and the way you have always dreamed of living. A brand-new product that will delight. Featuring three spacious bedrooms. Upstairs you will find a study nook that is perfect for your working from home requirements. A centralized kitchen makes it the real heart of the home. There is something for everyone."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "On going"
        },
        {
            title: "Land Size",
            data: "289 SQM"
        },
        {
            title: "Completion Date",
            data: "Oct 2021"
        },
        {
            title: "Total Project Value",
            data: " $950,000"
        },
        {
            title: "Client",
            data: "Sumeet Patel"
        },
      
       
    ],

} 

export const mitre_crescent_bayswater = {
    heading: "Mitre Crescent, Bayswater, 3153",
    gallery: [mitre_crescent_bayswater1, mitre_crescent_bayswater2],
    title: "Planning & Architecture",
    name: 'mitre_crescent_bayswater',
    description: [
        {
            paragraph: "Unlock the wealth with a dual occupancy development of artistically designed double story 3- and 4-bedroom townhouses. Underpinned by a dream location that offers easy access to an array of quality amenities, including walking distance to Bayswater West Primary, Bayswater Primary, Bayswater Secondary, Guy Turner Reserve, Bayswater Train Station, and Bayswater Shopping Centre.With modern living features such as: soft closing custom joinery, Turkish imported marble benchtops, floor to ceiling tiling in the bathrooms, rose gold tapware, engineered hybrid oak flooring"
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "On going"
        },
        {
            title: "Land Size",
            data: "728 SQM"
        },
        {
            title: "Completion Date",
            data: "Oct 2022"
        },
        {
            title: "Total Project Value",
            data: " $2,300,000 "
        },
        {
            title: "Expected Rental after completion",
            data: "$67,600 P.A. "
        },
        {
            title: "Client",
            data: "Dinesh Vekariya"
        },
      
       
    ],

}

export const burton_court_13_15_bayswater = {
    heading: "13-15 Burton Court, Bayswater, 3153",
    gallery: [burton_court_13_15_bayswater1, burton_court_13_15_bayswater2],
    title: "Planning & Architecture",
    name: 'burton_court_13_15_bayswater',
    description: [
        {
            paragraph: "Located in the heart of Bayswater, accompanied by residential dwellings, this property has been a hot cake investment. Acquisition of two warehouse shades and conversion to recreational centers for the local suburban’s to stay active and fit. With our new tenants transforming the whole facility into an extravaganza recreational center with state-of-the-art facilities offered."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: "7250 SQM"
        },
        {
            title: "Completion Date",
            data: "March 2020"
        },
        {
            title: "Total Project Value",
            data: " $8,700,000 "
        },
        {
            title: "Expected Rental after completion",
            data: " $352,000 P.A. "
        },
        {
            title: "Client",
            data: " V Developers Pty Ltd "
        },
      
       
    ],

} 

export const burton_court_15_bayswater = {
    heading: "15 Burton Court, Bayswater, 3153",
    gallery: [burton_court_15_bayswater1, burton_court_15_bayswater2],
    title: "Planning & Architecture",
    name: 'burton_court_15_bayswater',
    description: [
        {
            paragraph: "Located in the heart of Bayswater, accompanied by residential dwellings, this property has been a hot cake investment. Acquisition of two warehouse shades and conversion to recreational centers for the local suburban’s to stay active and fit. With our new tenants transforming the whole facility into an extravaganza recreational center with state-of-the-art facilities offered."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Planning and Research"
        },
        {
            title: "Land Size",
            data: "1000 SQM"
        },
        {
            title: "Completion Date",
            data: "August 2026"
        },
        {
            title: "Total Project Value",
            data: " $3,800,000 "
        },
        {
            title: "Expected Rental after completion",
            data: " $200,000 P.A. "
        },
        {
            title: "Client",
            data: " V Developers Pty Ltd "
        },
      
       
    ],

}

export const burton_court_17_bayswater = {
    heading: "17 Burton Court, Bayswater, 3153",
    gallery: [burton_court_17_bayswater1, burton_court_17_bayswater2],
    title: "Planning & Architecture",
    name: 'burton_court_17_bayswater',
    description: [
        {
            paragraph: "Located within the heart of Bayswater with easy access to the property. The location brags about its easy accessibility to parents and guardians.  With a massive development of a 96-place capacity childcare facility, the location falls under the eyes of lucrative investors, with a long-term lease signed by a leading Australian care provider firm."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "on going"
        },
        {
            title: "Land Size",
            data: "1550 SQM"
        },
        {
            title: "Completion Date",
            data: "December 2022"
        },
        {
            title: "Total Project Value",
            data: "  $5,600,000 "
        },
        {
            title: "Expected Rental after completion",
            data: " $353,700 P.A. "
        },
        {
            title: "Client",
            data: " V Developers Pty Ltd "
        },
      
       
    ],

}

export const cumberland_avenue_bayswater = {
    heading: "Cumberland Avenue, Bayswater, 3153",
    gallery: [cumberland_avenue_bayswater1, cumberland_avenue_bayswater2],
    title: "Planning & Architecture",
    name: 'cumberland_avenue_bayswater',
    description: [
        {
            paragraph: "A sprawling 10 acres of open land emerging from residential surrounds, within a 5-minute drive to Bayswater Central and only a “hop, skip and a jump” to Eastlink via Boronia Road. Construction of a community-based center with branches in over 65 countries and 700+ organization under their umbrella with major earthworks and grading of the property."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "on going"
        },
        {
            title: "Land Size",
            data: "41,800 SQM"
        },
        {
            title: "Completion Date",
            data: "Oct 2022"
        },
        {
            title: "Total Project Value",
            data: " $4,900,000  "
        },
        {
            title: "Client",
            data: "  Shree Swaminarayan Temple Melbourne "
        },
      
       
    ],

}

export const lysterfield_road_lysterfield = {
    heading: "Lysterfield Road, Lysterfield, 3156",
    gallery: [lysterfield_road_lysterfield1, lysterfield_road_lysterfield2],
    title: "Planning & Architecture",
    name: 'lysterfield_road_lysterfield',
    description: [
        {
            paragraph: "As Melbourne’s population continues to grow and the pressure to spread into its various growth corridors continues to intensify, the opportunities presented by this extraordinary block of 187,000 sqms are most exciting. Positioned near the foot of the Dandenong Range, this extraordinary farmland allotment has been in the same family since 1866 and is sure to attract attention as Melbourne’s population continues to crave a rural experience with easy city convenience. The property is situated between two major proposed road intersections which adds a cherry on the cake. With potential scope for lot subdivision aligned with our long term goals of development and short term gains from high tech farming in a glass house structure and rural development. Having insights for future plan use, the property falls under our land banking proposal with a lucrative future."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "On going planning"
        },
        {
            title: "Land Size",
            data: "187,000 SQM"
        },     
    ],

}

export const north_road_albury = {
    heading: "North Road, Albury, 2640",
    gallery: [north_road_albury1, north_road_albury2],
    title: "Planning & Architecture",
    name: 'north_road_albury',
    description: [
        {
            paragraph: "Located in one of the most populous regional towns in NSW which boost most of the major government owned offices. The acquisition of the whole lot which comprises of 6 tenants utilisng the facility with future expansion plans in the tube. With surplus land on the rear side of the property new tenants are eagerly waiting to utilise more built up area."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Completed"
        },
        {
            title: "Land Size",
            data: " 30,018 SQM"
        },  
        {
            title: "Investment value",
            data: " $6,400,000 "
        },  
        {
            title: "Client",
            data: " V Developers Pty Ltd "
        },   
    ],

}


export const north_road_rear_side_albury = {
    heading: "North Road (Rear Side), Albury, 2640",
    gallery: [north_road_rear_side_albury1, north_road_rear_side_albury2],
    title: "Planning & Architecture",
    name: 'north_road_rear_side_albury',
    description: [
        {
            paragraph: "With 6 tenants utilising the front area of the property, we plan to develop 30 strata factories with modern facilities and technology. With future potential trade growth in the region we align our development with short term rental growth and long term growth with retail and commercial development."
        }
    ],
    projectDetails: [
        {
            title: "Current Status",
            data: "Planning"
        },
        {
            title: "Land Size",
            data: " 14,000 SQM"
        },   
        {
            title: "Client",
            data: " V Developers Pty Ltd "
        },   
    ],

}

export const allProjects = [corner_of_dorset_and_edina_road_ferntree_gully, stud_road_rowville, the_haven_bayswater, hayden_court_knoxfield, murray_crescent_rowville, scoresby_road_368_ferntree_gully, peck_street_bayswater, kevin_avenue_ferntree_gully, david_street_knoxfield, gresford_road_wantirna, yarran_grove_bayswater, charlotte_road_boronia, springsong_pass_chirnside, mitre_crescent_bayswater, burton_court_13_15_bayswater, burton_court_15_bayswater, burton_court_17_bayswater , cumberland_avenue_bayswater, lysterfield_road_lysterfield, north_road_albury, north_road_albury, north_road_rear_side_albury]