import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Logo from '../assets/images/logo/logoSubheading.png';
import ConsultationImg from '../assets/images/consultation-2.png'

const DevelopmentConsultations = () => {
    return (
        <div>
            <ScrollAnimation animateIn="fadeInUp">
                <img src={ConsultationImg} alt="Planning" className="img-fluid" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <h4 className="heading-4">Development Consultation</h4>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <p>With over 12 years of experience and polished Architects & Engineers — Our Consultancy in planning and managing iconic/complex developments have proved us to be on the Top in the eastern parts of Melbourn - Australia.</p>
                <p><img src={Logo} alt='logo' className='img-fluid logo' /> has always got a Tag of Guarantee to satisfy its clients/customers in fulfilling their needs in all respects.</p>
                <p><img src={Logo} alt='logo' className='img-fluid logo' /> has always focused on the best choice of materials to be consumed with complete infrastructure / low maintenance cost consultancy rendered by its Architects & Engineers.</p>
                <p><img src={Logo} alt='logo' className='img-fluid logo' /> Consultants conduct several sittings/meetings with their clients to discuss & review the project design / budget, before the start of the project to maintain complete transparency and to avoid unnecessary loss of time & money and also to protect themselves from expensive defect claims, which is very vital.</p>
            </ScrollAnimation>
        </div>
    )
}

export default DevelopmentConsultations
