import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from 'react-bootstrap/';
import Client1 from '../assets/images/clients/client-1.png'
import Client2 from '../assets/images/clients/client-2.png'
import Client3 from '../assets/images/clients/client-3.png'
import Client4 from '../assets/images/clients/client-4.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clients = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Container className="clients">
                <ScrollAnimation animateIn="fadeInUp">
                    <div class="before-2 type-2">
                        {/* <h3><img src={Logo} alt='logo' className='img-fluid' /></h3> */}
                        <p className="stroke">CLIENTS</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <h2 className="text-center">Our Valued Customers</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <Slider {...settings}>
                        <img src={Client1} alt="Client" className="img-fluid" />
                        <img src={Client2} alt="Client" className="img-fluid" />
                        <img src={Client3} alt="Client" className="img-fluid" />
                        <img src={Client4} alt="Client" className="img-fluid" />
                    </Slider>
                </ScrollAnimation>
            </Container>
        </div>
    )
}

export default Clients
