import React, { useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Col, Row } from 'react-bootstrap/';
import Project1 from '../assets/images/projects/project-1.jpg'
import Project2 from '../assets/images/projects/project-2.jpg'
import Project3 from '../assets/images/projects/project-3.jpg'
import { List, ListItem, ListItemText } from '@material-ui/core';
import PropertyDevelopment from '../services/PropertyDevelopment'
import DevelopmentConsultations from '../services/DevelopmentConsultations'
import ProjectManagement from '../services/ProjectManagement'
import BuildingAndConstruction from '../services/BuildingAndConstruction'

const Services = () => {
    const [selectedService, setSelectedService] = useState(<PropertyDevelopment />)
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 0) {
            setSelectedService(<PropertyDevelopment />)
        } else if (index === 1) {
            setSelectedService(<DevelopmentConsultations />)
        } else if (index === 2) {
            setSelectedService(<ProjectManagement />)
        } else if (index === 3) {
            setSelectedService(<BuildingAndConstruction />)
        }
    };

    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Services</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container className="service-detail">
                    <Row>
                        <Col md={3}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="card-sidemenu">
                                    <h2 className="heading-3">All Services</h2>
                                    <List component="nav" aria-label="main mailbox folders">
                                        <ListItem
                                            button
                                            selected={selectedIndex === 0}
                                            onClick={(event) => handleListItemClick(event, 0)}
                                        >
                                            <ListItemText primary="Property Development" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            selected={selectedIndex === 1}
                                            onClick={(event) => handleListItemClick(event, 1)}
                                        >
                                            <ListItemText primary="Development Consultation" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            selected={selectedIndex === 2}
                                            onClick={(event) => handleListItemClick(event, 2)}
                                        >
                                            <ListItemText primary="Project Management" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            selected={selectedIndex === 3}
                                            onClick={(event) => handleListItemClick(event, 3)}
                                        >
                                            <ListItemText primary="Building And Construction" />
                                        </ListItem>
                                    </List>
                                </div>
                            </ScrollAnimation>
                        </Col>
                        <Col md={9}>
                            {selectedService}
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="cmb-1">
                <Container className="related-projects">
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2 className="heading-3">Related Projects</h2>
                    </ScrollAnimation>
                    <Row>
                        <Col md={4}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="project-single">
                                    <img src={Project1} alt="Project" className="img-fluid" />
                                    <h4>Rowville</h4>
                                </div>
                            </ScrollAnimation>
                        </Col>
                        <Col md={4}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="project-single">
                                    <img src={Project2} alt="Project" className="img-fluid" />
                                    <h4>Wantirna</h4>
                                </div>
                            </ScrollAnimation>
                        </Col>
                        <Col md={4}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="project-single">
                                    <img src={Project3} alt="Project" className="img-fluid" />
                                    <h4>Rowville</h4>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Services
