import React from 'react'
// Library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const IntroSlider = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings}>
                <div>
                    <h1>You Dream it, We Build it.</h1>
                    <a href="/about-us"><p>Learn more About Us <ArrowForwardIcon /></p></a>
                </div>
                <div>
                    <h2>We just don't Construct,</h2>
                    <h2>We do develop your Future.</h2>
                    <a href="/about-us"><p>Learn more About Us <ArrowForwardIcon /></p></a>
                </div>
                <div>
                    <h2>Most Dependable Service Provider</h2>
                    <h2>At your doorstep.</h2>
                    <a href="/about-us"><p>Learn more About Us <ArrowForwardIcon /></p></a>
                </div>
                <div>
                    <h2>Building A Better Future.</h2>
                    <a href="/about-us"><p>Learn more About Us <ArrowForwardIcon /></p></a>
                </div>
            </Slider>
        </div>
    )
}

export default IntroSlider
