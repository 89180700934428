import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Col, Row } from 'react-bootstrap/';
import { allProjects } from '../projectsData';

const ProjectDetails = (props) => {
    const projectTitle = props.match.params.projectTitle
    const [projectName, setProjectName] = useState({
        heading: "",
        gallery: [],
        title: "",
        description: [],
        projectDetails: [],
        relatedProjects: []
    })

    var settings = {
        customPaging: function (i) {
            return (
                // eslint-disable-next-line
                <a><img src={projectName.gallery[i]} alt="project" className="img-fluid" /></a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };    

    useEffect(() => {
        getProject(projectTitle)       
        // eslint-disable-next-line
    }, [])
    const getProject = (projectTitle) => {
        allProjects.map((item, index)=>{
            const found = projectTitle === item.name
            if (found) return setProjectName(allProjects[index])
            else return null            
            ; })
        window.scrollTo(0, 0)
    }
    return (
        <div className="project-details">
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>{projectName.heading}</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li><a href="/our-projects">Projects</a></li>
                        <li>{projectName.heading}</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <Slider {...settings}>
                            {projectName.gallery.map((item, index) => {
                                return (
                                    <img key={index} src={item} alt="Project" className="img-fluid" />
                                )
                            })}
                        </Slider>
                    </ScrollAnimation>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="card-project-details">
                        <ScrollAnimation animateIn="fadeInUp">
                            <h2 className="heading-5">{projectName.title}</h2>
                        </ScrollAnimation>
                        {projectName.description.map((item, index) => {
                            return (
                                <ScrollAnimation animateIn="fadeInUp">
                                    <p key={index}>{item.paragraph}</p>
                                </ScrollAnimation>
                            )
                        })}

                        <ScrollAnimation animateIn="fadeInUp">
                            <h2 className="heading-5">Project Details</h2>
                        </ScrollAnimation>
                        <Row className="data">
                            {projectName.projectDetails.map((item, index) => {
                                return (
                                    <Col lg={6} xs={12} key={index}>
                                        <ScrollAnimation animateIn="fadeInUp" className='d-flex justify-content-start align-items-baseline'>
                                            <h3>{item.title} :&nbsp;</h3>
                                            <h4>&nbsp;{item.data}</h4>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </Container>
            </section>
            {/* <section className="cmb-1">
                <Container className="related-projects">
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2 className="heading-3">Related Projects</h2>
                    </ScrollAnimation>
                    <Row>
                        {projectName.relatedProjects.map((item, index) => {
                            return (
                                <Col md={4} key={index}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <Link to={item.url} onClick={() => getProject(item.url)}>
                                            <div className="project-single">
                                                <img src={item.img} alt={item.title} className="img-fluid" />
                                                <h4>{item.title}</h4>
                                            </div>
                                        </Link>
                                    </ScrollAnimation>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section> */}
        </div>
    )
}

export default ProjectDetails
