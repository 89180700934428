import React from 'react'
// BootStrap
import About from '../assets/images/about-us.png'
import About2 from '../assets/images/about-2.png'
import Build from '../assets/images/build.png'
import ScrollAnimation from 'react-animate-on-scroll';
import BussinessGrowth from '../assets/images/bussiness-growth.png'
import { Container, Col, Row } from 'react-bootstrap/';
import Logo from '../assets/images/logo/logoSubheading.png';
import Mission from '../components/Mission';
import Clients from '../components/Clients';

const AboutUs = () => {
    return (
        <div>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Company</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Company</li>
                    </ul>
                </div>
            </section>
            <section id="about" className="entry">
                <Container>
                    <Row>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={About} alt="About Us" className="img-fluid d-none d-sm-block" />
                            </ScrollAnimation>
                        </Col>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="before type-2">
                                    {/* <h3><img src={Logo} alt='logo' className='img-fluid' /></h3> */}
                                    <span>ABOUT US</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h2>Who We Are ?</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={About} alt="About Us" className="img-fluid d-block d-sm-none" />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p><img src={Logo} alt='logo' className='img-fluid logo' /> is a Melbourne - Australia based, Promoters &amp; Developers, Builders & Contractors(Civil Construction) Co. with all the latest State-of-the-Art-Technology duly specialised in Unique Architecture in residential as well as commercial constructions.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p><img src={Logo} alt='logo' className='img-fluid logo' /> has emerged as one of the leading landmark property developers in the eastern suburban areas of Melbourne - Australia, with completed projects worth $500 million or more.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p className='mt-5'><img src={Logo} alt='logo' className='img-fluid logo' /> has got a proven track record of <span className='bradley-2'>ON-TIME</span> possession with <span className='bradley-2'>BUDGET-FUNDS</span> and <span className='bradley-2'>UTMOST SATISFACTION</span> of its Customers.</p>
                            </ScrollAnimation>
                        </Col>
                        <ScrollAnimation animateIn="fadeInUp">
                            <p>Apart from our Independent Projects, We also undertake projects with Land Owners on Joint Venture basis.</p>
                        </ScrollAnimation>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p><img src={Logo} alt='logo' className='img-fluid logo' /> team-members conduct an over-all research of a particular Land before the purchase deal with high speculation of future potential of the area and on maturity develops, builds & sells the respective unit to the prospective customer.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={About2} alt="About Us" className="img-fluid d-block d-sm-none" />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p>The <span className='bradley-2'>SUCCESS</span> of <img src={Logo} alt='logo' className='img-fluid logo' /> can be attributed to their meticulously calculated policy, hardwork, dedication & devotion duly gained over the past years.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p><img src={Logo} alt='logo' className='img-fluid logo' /> has completed & delivered landmark properties worth $112.75 millions and has also built up good and healthy business relationship with Councillors, Architects & Civil Engineers of the local area.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p><img src={Logo} alt='logo' className='img-fluid logo' /> ultimate <span className='bradley-2'>AIM - GOAL</span> is to provide complete Satisfaction to its Valuable Customers ( residential / commercial ) and to always remain as one of the top major player in the Civil Construction Industry serving in the area of eastern suburbs of Melbourne - Australia.</p>
                            </ScrollAnimation>
                        </Col>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={About2} alt="About Us" className="img-fluid d-none d-sm-block" />
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <section id="build">
                <Container>
                    <Row>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="before">
                                    <h3>VConcrateBuild</h3>
                                    <span>Build</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h2>VConcrate Build</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={Build} alt="About Us" className="img-fluid d-block d-sm-none" />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Located in Melbourne, Australia, VConcrete Build specialises in constructing distinctive, well-structured, modern residences, apartments, and commercial buildings.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Our primary objective is to provide the modern infrastructure that every Australian needs.</p>
                            </ScrollAnimation>
                        </Col>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={Build} alt="About Us" className="img-fluid d-none d-sm-block" />
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h3 className="heading-2">Business Growth</h3>
                    </ScrollAnimation>
                    <div className="text-center">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={BussinessGrowth} alt="Bussiness Growth" className="img-fluid b-growth" />
                        </ScrollAnimation>
                        <div className='mt-5'>
                            <strong className='ft-22'>
                                Since then our Wheels of Success are still on the move . . . . . to achieve many more Milestones . . . . .
                            </strong>
                        </div>
                    </div>
                </Container>
            </section>
            <Mission />
            <div className="cmb-1">
                <Clients />
            </div>
        </div>
    )
}

export default AboutUs
