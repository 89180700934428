// Core
import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import { useLocation } from "react-router-dom";
// BootStrap
import { Container, Navbar, NavDropdown, Nav } from 'react-bootstrap';
// Library
import {Button, Drawer, Divider, makeStyles, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';
// Media
import PhoneIcon from '@material-ui/icons/Phone';
import Logo from '../assets/images/logo/v-developers-logo.png';
import MenuIcon from '../assets/images/menu-icon-top.svg'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Header = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    let location = useLocation().pathname;
    const [urlHomepage, setUrlHomepage] = useState('')
    const [urlCompany, setUrlCompany] = useState('')
    const [urlServices, setUrlServices] = useState('')
    const [urlProjects, setUrlProjects] = useState('')
    const [urlContactUs, setUrlContactUs] = useState('')

    useEffect(() => {
        checkURL()
        // eslint-disable-next-line
    }, [])

    const handleClick = () => {
        setOpen(!open);
    };

    const checkURL = () => {
        if (location === "/") {
            setUrlHomepage('active');
        } else if (location === "/about-us") {
            setUrlCompany('active');
        } else if (location === "/services") {
            setUrlServices('active');
        } else if (location.includes('/our-projects')) {
            setUrlProjects('active');
        } else if (location === "/contact-us") {
            setUrlContactUs('active');
        }
    }

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };;

    const onButtonClick = () => {
                const a = document.createElement('a')
                a.href = 'V-Developers Company Profile.pdf'
                a.download = 'V-Developers Company Profile.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
    }
    return (
        <div className="header sticky">
            <Container>
                <Navbar expand="lg">
                    {['left'].map((anchor, index) => (
                        <div key={index}>
                            <img src={MenuIcon} alt="Menu" className="d-block d-sm-none menu-icon" onClick={toggleDrawer(anchor, true)} />
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                <div
                                    className={clsx(classes.list, {
                                        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                                    })}
                                    role="presentation"
                                    onKeyDown={toggleDrawer(anchor, false)}
                                >
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <ListSubheader component="div" className="text-center" id="nested-list-subheader">
                                                <img src={Logo} alt="Logo" />
                                            </ListSubheader>
                                        }
                                        className={classes.root}
                                    >
                                        <Divider className="mt-4" />
                                        <div>
                                            <a href="/">
                                                <ListItem button>
                                                    <ListItemIcon><KeyboardReturnIcon className="fa-flip-horizontal" /></ListItemIcon>
                                                    <ListItemText primary="Home" />
                                                </ListItem>
                                            </a>
                                            <a href="/about-us">
                                                <ListItem button>
                                                    <ListItemIcon><KeyboardReturnIcon className="fa-flip-horizontal" /></ListItemIcon>
                                                    <ListItemText primary="Company" />
                                                </ListItem>
                                            </a>
                                            <a href="/services">
                                                <ListItem button>
                                                    <ListItemIcon><KeyboardReturnIcon className="fa-flip-horizontal" /></ListItemIcon>
                                                    <ListItemText primary="Services" />
                                                </ListItem>
                                            </a>
                                            <ListItem button onClick={handleClick}>
                                                <ListItemIcon><KeyboardReturnIcon className="fa-flip-horizontal" /></ListItemIcon>
                                                <ListItemText primary="Projects" />
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <a href="/our-projects/development-projects">
                                                        <ListItem button className="ms-5">
                                                            <ListItemText primary="Development" />
                                                        </ListItem>
                                                    </a>
                                                    <a href="/our-projects/construction-projects">
                                                        <ListItem button className="ms-5">
                                                            <ListItemText primary="Construction" />
                                                        </ListItem>
                                                    </a>
                                                </List>
                                            </Collapse>
                                            <a href="/contact-us">
                                                <ListItem button>
                                                    <ListItemIcon><KeyboardReturnIcon className="fa-flip-horizontal" /></ListItemIcon>
                                                    <ListItemText primary="Contact Us" />
                                                </ListItem>
                                            </a>
                                        </div>
                                    </List>
                                </div>
                            </Drawer>
                        </div>
                    ))}
                    <Navbar.Brand href="/">
                        <img src={Logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className='align-items-baseline'>
                            <Nav.Link href="/" className={urlHomepage}>Home</Nav.Link>
                            <Nav.Link href="/about-us" className={urlCompany}>Company</Nav.Link>
                            <Nav.Link href="/services" className={urlServices}>Services</Nav.Link>
                            <NavDropdown className={urlProjects} title="Projects" >
                                <NavDropdown.Item href="/our-projects/development-projects">Development</NavDropdown.Item>
                                <NavDropdown.Item href="/our-projects/construction-projects">Construction</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact-us" className={urlContactUs} style={{marginRight:'2em'}}>Contact Us</Nav.Link>
                            {/* <Nav.Link onClick={onButtonClick} >Get Brochure</Nav.Link> */}
                            <Button onClick={onButtonClick} className="btn-style-1">Company Profile</Button>
                            <Nav.Link href="tel:+61 424 554 504" className="btn-style-1"><PhoneIcon /> +61 424 554 504</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    )
}

export default Header