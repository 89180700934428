// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import AboutUs from './pages/AboutUs'
import Projects from './pages/Projects'
import ContactUs from './pages/ContactUs'
import Services from './pages/Services'
import ProjectsDevelopment from './pages/ProjectsDevelopment'
import ProjectsConstruction from './pages/ProjectsConstruction'
import ProjectDetails from './pages/ProjectDetails'
import Sitemap from './pages/Sitemap'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ThankYou from './pages/ThankYou'
import Maintainance from './components/Maintainance'

const underMaintainance = false

function App() {
    return (
        underMaintainance ? (
            <div>
                <Router>
                    <Switch>
                        <Route exact path='/' component={Maintainance} />
                    </Switch>
                </Router>
            </div>
        )
        : (<div>
            <Router>
                <Header />
                <Switch>
                    <Route exact path='/' component={Homepage} />
                    <Route exact path='/about-us' component={AboutUs} />
                    <Route exact path='/services' component={Services} />
                    <Route exact path='/our-projects' component={Projects} />
                    <Route exact path='/our-projects/development-projects' component={ProjectsDevelopment} />
                    <Route exact path='/our-projects/construction-projects' component={ProjectsConstruction} />
                    <Route exact path='/contact-us' component={ContactUs} />
                    <Route exact path='/our-projects/:projectTitle' component={ProjectDetails} />
                    <Route exact path='/sitemap' component={Sitemap} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route exact path='/thank-you' component={ThankYou} />
                </Switch>
                <Footer />
            </Router>
        </div>)
    );
}

export default App;
